import {
    UPDATE_SERVIZIO
} from "../queries/servizi";
import axios from "axios";

export const updateServizi = async (graphql, fields, id) => {
    const response = await axios({
        method: "post",
        url: graphql.url,
        data: {
          query: UPDATE_SERVIZIO,
          variables: {
            id,
            fields
          },
        },
        headers: {
          Authorization: `Bearer ${graphql.token}`,
        },
    });
    return response;
};

