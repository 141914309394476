import {
    GET_FORNITORE
} from "../queries/fornitore";
import axios from "axios";

export const fetchFornitori = async (graphql, variables) => {
    const response = await axios({
      method: "post",
      url: graphql.url,
      data: {
        query: GET_FORNITORE,
        variables,
      },
      headers: {
        Authorization: `Bearer ${graphql.token}`,
      },
    });
    return response;
};

