import React, { useCallback, useState } from "react";
import { Card, Collapse, Flex, Button, Popconfirm } from "antd";
import _ from "lodash";
import { ofcUrl } from "../../api_config.json";
import { useAppContext } from "../../context";
import { useAuth0 } from "../../react-auth0-spa";
import axios from "axios";
import config from "../../api_config.json";

const { Panel } = Collapse;
const mutationDeleteDocument = `
mutation DeleteDocument($id: ID!) {
    deleteOrg_doc(input: { id: $id }) {
      org_doc {
        objectId
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const queryDocuments = `
query FetchDocuments($where: Org_docWhereInput) {
  org_docs(where: $where) {
    edges {
      node {
        objectId,
        nome_documento,
        precompilato,
        generico,
        documento,
        pdf_form {
          url
        }
      }
    }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const TabOrgDocs = (props) => {
  const { docs, orgId } = props;
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { getTokenSilently } = useAuth0();
  const sortedDocs = _.orderBy(docs, ["nome_documento"], ["asc"]);
  const [docState, setDocState] = useState([...sortedDocs]);

  const deleteDoc = useCallback(
    async (docId) => {
      try {
        const token = await getTokenSilently();
        let graphqlUrl = `${config.baseUrl[env]}/graphql`;

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: mutationDeleteDocument,
            variables: { id: docId },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, env]
  );

  const reloadDocs = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      let graphqlUrl = `${config.baseUrl[env]}/graphql`;

      const response = await axios({
        method: "post",
        url: graphqlUrl,
        data: {
          query: queryDocuments,
          variables: {
            where: { org_pointer: { have: { objectId: { equalTo: orgId } } } },
          },
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setDocState([...response.data]);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [env, getTokenSilently, orgId]);

  return (
    <Collapse>
      {docState.map((doc, index) => {
        let title = doc.nome_documento || "TITOLO MANCANTE";
        if (doc.precompilato === false) {
          title = `${title} [Precompilato]`;
        }
        if (doc.generico) {
          title = `${title} [Generico]`;
        }
        return (
          <Panel header={title} key={index}>
            <div className="site-card-border-less-wrapper">
              <Card
                bordered={false}
                style={{ width: "100%" }}
                extra={
                  <Flex gap={"small"} justify={"flex-end"} align={"center"}>
                    <Button
                      type="primary"
                      href={`${
                        ofcUrl[isProd ? "prod" : "dev"]
                      }/index.php?page=modificaTemplateDocumento&documentId=${
                        doc.objectId
                      }`}
                    >
                      Modifica su Eterno
                    </Button>
                    <Popconfirm
                      title="Elimina il documento"
                      description="Sei sicuro di voler eliminare questo documento?"
                      onConfirm={async () => {
                        await deleteDoc(doc.objectId);
                        await reloadDocs();
                      }}
                      okText="Si"
                      cancelText="No"
                    >
                      <Button danger>Elimina</Button>
                    </Popconfirm>
                  </Flex>
                }
              >
                {doc.pdf_form && (
                  <object
                    data={doc.pdf_form.url}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    style={{
                      width: "80vw",
                      height: "100vh",
                    }}
                  >
                    PDF embed not working
                  </object>
                )}

                {!doc.pdf_form && (
                  <div dangerouslySetInnerHTML={{ __html: doc.documento }} />
                )}
              </Card>
            </div>
          </Panel>
        );
      })}
    </Collapse>
  );
};

export default TabOrgDocs;
