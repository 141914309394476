import React, { useCallback, useEffect, useState } from "react";
import { useAppContext } from "../../context";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { Breadcrumb, Divider, Space, Typography } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress } from "@material-ui/core";
import DocScannerCard from "./DocScannerCard";

const { Title } = Typography;

const DocScanner = () => {
  const [items, setItems] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const { isProd } = useAppContext();
  const { getTokenSilently } = useAuth0();

  const fetchData = useCallback(
    async (from) => {
      const limit = 12;

      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${
            config.baseUrl[isProd ? "prod" : "dev"]
          }/doc-scanner-results?limit=${limit}${
            from ? `&from=${btoa(from)}` : ""
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();
        return responseData;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently]
  );

  useEffect(() => {
    fetchData().then(({ data }) => {
      if (data.items.length === 0) {
        setHasMore(false);
      }
      setItems(data.items);
      setLastEvaluatedKey(data.lastEvaluatedKey);
    });
  }, [fetchData, setItems]);

  return (
    <div style={{ margin: "16px 16px" }}>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Eterno</Breadcrumb.Item>
        <Breadcrumb.Item>Doc Scanner</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>Doc Scanner</Space>
      </Title>
      <Divider />
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          fetchData(lastEvaluatedKey).then(({ data }) => {
            setLastEvaluatedKey(data.lastEvaluatedKey);
            setItems(items.concat(data.items));
            if (data.items.length === 0) {
              setHasMore(false);
            }
          });
        }}
        hasMore={hasMore}
        loader={<LinearProgress />}
      >
        <div className="grid-container">
          {items.map((scanResult, index) => {
            const jsonData = scanResult.outputJsonDefunto
              ? JSON.parse(scanResult.outputJsonDefunto.replace("'", ""))
              : undefined;
            return (
              <DocScannerCard
                key={index}
                createdAt={scanResult.createdAt}
                input={scanResult.files}
                resultJson={jsonData}
                resultPdf={
                  scanResult.outputPdfS3Key &&
                  `${config.files[isProd ? "prod" : "dev"]}/${
                    scanResult.outputPdfS3Key
                  }`
                }
                error={
                  !scanResult.outputJsonDefunto && !scanResult.outputPdfS3Key
                }
                code={scanResult.requestType}
                orgLink={`/clients/${scanResult.orgId}`}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default DocScanner;
