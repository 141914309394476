import React from "react";
import { Alert, Flex } from "antd";

const InputPreview = ({ input }) => {
  return (
    <>
      <Flex gap={10} wrap={"wrap"} style={{ marginBottom: "10px" }}>
        {input &&
          input.map((file, index) => (
            <div
              style={{
                flexBasis: input.length === 1 ? "100%" : "48%",
              }}
            >
              {/\.(jpg|jpeg|png)$/i.test(file) ? (
                <a href={file} target="_blank" rel="noopener noreferrer">
                  <img
                    src={file}
                    alt=""
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      zIndex: 1,
                      opacity: 0.7,
                      flexBasis: "100%",
                    }}
                  />
                </a>
              ) : /\.pdf$/i.test(file) ? (
                <object
                  data={file}
                  type="application/pdf"
                  style={{
                    width: "100%",
                    minHeight: "300px",
                  }}
                >
                  PDF embed non funzionante
                </object>
              ) : null}
            </div>
          ))}
      </Flex>
      {!input && (
        <Alert
          message={"Impossibile recuperare i documenti inseriti"}
          type="warning"
          showIcon={true}
          style={{ marginBottom: "10px" }}
        />
      )}
    </>
  );
};

export default InputPreview;
