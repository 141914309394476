import { UPDATE_CLIENTE } from "../queries/cliente";

import axios from "axios";

export const updateCliente = async (graphql, variables) => {
  const response = await axios({
    method: "post",
    url: graphql.url,
    data: {
      query: UPDATE_CLIENTE,
      variables
    },
    headers: {
      Authorization: `Bearer ${graphql.token}`,
    },
});
  return response;
};

