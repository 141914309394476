export const GET_FORNITORE = `
  query GetFornitore($where: FornitoriWhereInput) {
    fornitoris(where: $where) {
      edges {
        node {
          objectId
          ragione_sociale
          citta
          partita_iva
          telefono        
          org_pointer {
            objectId
          }
          centro_servizi_pointer {
            objectId
          }
        }
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

export const DELETE_FORNITORE = `
  mutation DeleteFornitore($id: ID!) {
    deleteFornitori(input: { id: $id }) {
      fornitori {
        objectId
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");