export const UPDATE_CLIENTE = `
  mutation DissociateCliente($id: ID!, $fields: UpdateClienteFieldsInput) {
      updateCliente(input: {id: $id, fields: $fields}) {
        cliente {
          objectId
        }
      }
    }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

export const GET_CLIENTI = `
query GetClienti($where: ClienteWhereInput) {
  clientes(first: 1000, where: $where) {
    edges {
      node {
        objectId
        cognome
        partita_iva
        org_pointer {
          objectId
        }
        terzista_org_pointer{
          objectId
        }
        invite_status
      }
    }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

export const GET_CLIENTE_BY_ID = `
query GetClienteById($id: ID!) {
  cliente(id: $id) {
    objectId
    cognome
    partita_iva
    org_pointer {
      objectId
    }
    terzista_org_pointer{
      objectId
    }
    invite_status
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");
