import {
    GET_ALLEGATI
} from "../queries/allegati";
import axios from "axios";

export const fetchAllegati = async (graphql, variables) => {
    const response = await axios({
      method: "post",
      url: graphql.url,
      data: {
        query: GET_ALLEGATI,
        variables,
      },
      headers: {
        Authorization: `Bearer ${graphql.token}`,
      },
    });
    return response;
};