export const dbQueryResultsGroupedItems = (queryResults = {}, pref) => {
  const userPrefArray = pref.key ? pref.key.split(".") : pref.name.split(".");
  const [group, action] = userPrefArray;
  const featurePrefId = pref.objectId;
  const featureDisabled = pref.disabled;
  const featureLabel = pref.label;
  const featureProduct = pref.product ? pref.product : pref.products;
  const featurePlans = pref.plans;
  const featureDescription = pref.description;

  if (!(group in queryResults)) {
    queryResults[group] = [
      {
        action,
        label: featureLabel,
        objectId: featurePrefId,
        disabled: featureDisabled,
        product: featureProduct,
        plans: featurePlans,
        description: featureDescription,
      },
    ];
  } else {
    queryResults[group].push({
      action,
      label: featureLabel,
      objectId: featurePrefId,
      disabled: featureDisabled,
      product: featureProduct,
      plans: featurePlans,
      description: featureDescription,
    });
  }

  return queryResults;
};

export const dbQueryResultsGroupedProducts = (queryResults = {}, pref) => {
  let featureProducts = pref.product ? [pref.product] : pref.products;

  if (featureProducts === undefined) {
    featureProducts = ["eterno"];
  }

  for (const featureProduct of featureProducts) {
    if (!(featureProduct in queryResults)) {
      queryResults[featureProduct] = [
        {
          ...pref,
        },
      ];
    } else {
      queryResults[featureProduct].push({
        ...pref,
      });
    }
  }

  return queryResults;
};
