export const GET_SERVIZI = `
query GetServiziByOrgId($orgId: ID) {
  servizis(where: {
    org_pointer: {
      have: {
        objectId: {
          equalTo: $orgId
        }
      }
    }
  }, first: 10000) {
    edges {
      node {
        objectId
        ACL{
          roles{
            write
            read
            roleName
          }
        }
      }
    }
  }
}`;
export const UPDATE_SERVIZIO = `
mutation DisabbinaClienteServizio($id: ID!, $fields: UpdateServiziFieldsInput) {
  updateServizi(input: {id: $id, fields: $fields}) {
    servizi{
      objectId
    }
  }
}`;