import React from "react";
import _ from "lodash";
import { Space, Card, Carousel, Tag, Tabs } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { giottoUrl } from "../../api_config.json";
import { useAppContext } from "../../context";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";

const { TabPane } = Tabs;

const AssetsPreview = ({ imageUrl }) => {
  return (
    <div className="grid-item">
      <Card style={{ width: "25vw" }}>
        <LazyLoadImage width="100%" src={imageUrl} alt={imageUrl} />

        {/* <Tag style={{ marginTop: "10px" }}>
          {formatDistance(new Date(updatedAt), new Date(), { locale: it })}
        </Tag>
        <Tag style={{ marginTop: "10px" }} color={status === 'hidden' ? 'volcano' : 'green'}>
          {status === 'hidden' ? 'ELIMINATO' : 'ATTIVO'}
        </Tag> */}
      </Card>
    </div>
  );
};

const GiottoPreview = ({
  title,
  url,
  pages = [],
  createdAt,
  updatedAt,
  status,
}) => {
  return (
    <div className="grid-item">
      <Card
        title={title}
        extra={
          <a href={url} target="_blank" rel="noopener noreferrer">
            Apri
          </a>
        }
        style={{ width: "25vw" }}
      >
        <Carousel autoplay>
          {pages.map((page) => {
            if (!page || !page.preview || !page.preview.url) {
              return "";
            }

            return (
              <div>
                <LazyLoadImage
                  width="100%"
                  src={page.preview.url}
                  alt={page.objectId}
                />
              </div>
            );
          })}
        </Carousel>
        <Tag style={{ marginTop: "10px" }}>
          aggiornato{" "}
          {formatDistance(new Date(updatedAt), new Date(), { locale: it })}
        </Tag>
        <Tag style={{ marginTop: "10px" }}>
          creato{" "}
          {formatDistance(new Date(createdAt), new Date(), { locale: it })}
        </Tag>
        <Tag
          style={{ marginTop: "10px" }}
          color={status === "hidden" ? "volcano" : "green"}
        >
          {status === "hidden" ? "ELIMINATO" : "ATTIVO"}
        </Tag>
      </Card>
    </div>
  );
};

const TabGiotto = (props) => {
  const {
    editorCanvases = [],
    editorTemplates = [],
    giottoImages = [],
    giottoBackgrounds = [],
  } = props;
  const { isProd } = useAppContext();

  return (
    <Tabs defaultActiveKey="Progetti">
      <TabPane tab={`Progetti (${editorCanvases.length})`} key="Progetti">
        <Space direction="vertical">
          <div className="grid-container">
            {editorCanvases.map((editorCanvas, index) => {
              const title = `${_.get(
                editorCanvas,
                "metadata.projectTypeName",
                ""
              )} ${_.get(editorCanvas, "metadata.formatName", "")} ${_.get(
                editorCanvas,
                "metadata.templatesCategoryName",
                ""
              )}`;

              const url = `${giottoUrl[isProd ? "prod" : "dev"]}/canvas/${
                editorCanvas.objectId
              }`;

              return (
                <GiottoPreview
                  key={index}
                  url={url}
                  title={title}
                  createdAt={editorCanvas.createdAt}
                  updatedAt={editorCanvas.updatedAt}
                  status={editorCanvas.status}
                  pages={editorCanvas.editor_canvas_pages}
                />
              );
            })}
          </div>
        </Space>
      </TabPane>
      <TabPane tab={`Templates (${editorTemplates.length})`} key="Templates">
        <Space direction="vertical">
          <div className="grid-container">
            {editorTemplates.map((editorTemplate, index) => {
              const url = `${
                giottoUrl[isProd ? "prod" : "dev"]
              }/template-manager/${editorTemplate.objectId}`;

              return (
                <GiottoPreview
                  key={index}
                  url={url}
                  title={""}
                  createdAt={editorTemplate.createdAt}
                  updatedAt={editorTemplate.updatedAt}
                  status={editorTemplate.status}
                  pages={editorTemplate.editor_templates_pages}
                />
              );
            })}
          </div>
        </Space>
      </TabPane>
      <TabPane tab={`Images (${giottoImages.length})`} key="giottoImages">
        <Space direction="vertical">
          <div className="grid-container">
            {giottoImages.map((giottoImage) => {
              return <AssetsPreview imageUrl={giottoImage.file?.url} />;
            })}
          </div>
        </Space>
      </TabPane>
      <TabPane
        tab={`Backgrounds (${giottoBackgrounds.length})`}
        key="giottoBackgrounds"
      >
        <Space direction="vertical">
          <div className="grid-container">
            {giottoBackgrounds.map((giottoBackground) => {
              return <AssetsPreview imageUrl={giottoBackground.file?.url} />;
            })}
          </div>
        </Space>
      </TabPane>
    </Tabs>
  );
};

export default TabGiotto;
