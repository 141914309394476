import React, { useCallback, useEffect, useState } from "react";
import { Col, Collapse, Row, message } from "antd";
import { useAuth0 } from "../../../react-auth0-spa";
import config from "../../../api_config.json";
import { useAppContext } from "../../../context";
import {
  dbQueryResultsGroupedItems,
  dbQueryResultsGroupedProducts,
} from "./Utils/dbQueryResultsGrouped";
import FeatureGroup from "./FeatureGroup";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TabOrgFeatures = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const { orgId, org } = props;
  const [features, setFeatures] = useState(undefined);
  const [disabledFeatures, setDisabledFeatures] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchFeatures = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/features`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      const features = data
        .filter((item) => item.name !== undefined)
        .reduce(dbQueryResultsGroupedProducts, {});

      for (const product of Object.keys(features)) {
        features[product] = features[product].reduce(
          dbQueryResultsGroupedItems,
          {}
        );
      }
      setFeatures(features);
    } catch (error) {
      console.error(error);
    }
  }, [getTokenSilently, isProd]);

  const fetchFeaturesPreferences = useCallback(async () => {
    try {
      const token = await getTokenSilently();
      const response = await fetch(
        `${
          config.baseUrl[isProd ? "prod" : "dev"]
        }/featuresPreferences/${orgId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      const disabledFeatures = data
        .filter((item) => item.key !== undefined)
        .reduce(dbQueryResultsGroupedProducts, {});

      for (const product of Object.keys(disabledFeatures)) {
        disabledFeatures[product] = disabledFeatures[product].reduce(
          dbQueryResultsGroupedItems,
          {}
        );
      }

      setDisabledFeatures(disabledFeatures);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [getTokenSilently, isProd, orgId]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    await fetchFeatures();
    await fetchFeaturesPreferences();
    setLoading(false);
  }, [fetchFeatures, fetchFeaturesPreferences]);

  const setOrgFeaturePreference = async (data) => {
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${
          config.baseUrl[isProd ? "prod" : "dev"]
        }/setFeaturePreference/${orgId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleClick = async (data) => {
    if (data.key && data.disabled !== undefined) {
      message.loading({
        content: "Funzionalità in aggiornamento..",
        key: "loading",
        duration: 0,
      });

      const payload = {
        ...data,
      };
      try {
        await setOrgFeaturePreference(payload);
        await fetchFeaturesPreferences();
        message.destroy("loading");
        message.success("Funzionalità aggiornata con successo");
      } catch (error) {
        message.destroy("loading");
        message.error("Errore: Funzionalità non aggiornata");
      }
    }
  };

  useEffect(() => {
    async function run() {
      await fetchData();
    }

    run();
  }, [fetchData]);

  return (
    <div>
      {loading && <p>Caricamento...</p>}
      {!loading && features && (
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["eterno", "giotto"]}>
              {Object.keys(features)
                .sort((a, b) => a.localeCompare(b))
                .map((product) => {
                  return (
                    <CollapsePanel
                      header={capitalizeFirstLetter(product)}
                      key={product}
                    >
                      <Row>
                        {Object.keys(features[product]).map((key) => {
                          return (
                            <FeatureGroup
                              key={key}
                              group={key}
                              product={product}
                              items={features[product][key]}
                              disabledItems={disabledFeatures[product][key]}
                              handleClick={handleClick}
                              orgLicence={org.tipo_licenza}
                            />
                          );
                        })}
                      </Row>
                    </CollapsePanel>
                  );
                })}
            </Collapse>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default TabOrgFeatures;
