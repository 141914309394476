export const GET_SERVIZI_OFFERTI = `
query GetServiziOffertiByOrgId($orgId: ID) {
  servizi_offertis(where: {
    org_pointer: {
      have: {
        objectId: {
          equalTo: $orgId
        }
      }
    },
    hideToTerzista: { notEqualTo: true }
  }, first: 10000) {
    edges {
      node {
        objectId
        ACL{
          roles{
            write
            read
            roleName
          }
        }
      }
    }
  }
}`;

export const UPDATE_SERVIZI_OFFERTI = `
mutation DisabbinaClienteServiziOfferti($id: ID!, $fields: UpdateServizi_offertiFieldsInput) {
  updateServizi_offerti(input: {id: $id, fields: $fields}) {
    servizi_offerti {
      objectId
    }
  }
}`;

