import React from "react";
import { Card, Tag, Space } from "antd";
import { formatDistance } from "date-fns";
import { it } from "date-fns/locale";
import InputPreview from "./InputPreview";
import OutputPreview from "./OutputPreview";

const DocScannerCard = ({
  resultPdf,
  createdAt,
  input,
  resultJson,
  error,
  code,
  orgLink,
}) => {
  return (
    <div className="grid-item">
      <Card
        extra={
          <Space>
            {resultPdf && (
              <a
                href={resultPdf}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "1.2em" }}
              >
                Apri
              </a>
            )}
            {resultPdf && orgLink && " | "}
            {orgLink && (
              <a
                href={orgLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "1.2em" }}
              >
                Org
              </a>
            )}
          </Space>
        }
        style={{
          border: error && "2px solid #ff4d4f",
        }}
      >
        <div style={{ maxWidth: "100%" }}>
          <InputPreview input={input} />
          <OutputPreview
            resultPdf={resultPdf}
            resultJson={resultJson}
            code={code}
            error={error}
          />
        </div>
        <Tag
          style={{
            background: "#fafafa",
            marginTop: "10px",
            marginRight: "0px",
          }}
        >
          {formatDistance(new Date(createdAt * 1000), new Date(), {
            locale: it,
          })}
        </Tag>
      </Card>
    </div>
  );
};

export default DocScannerCard;
