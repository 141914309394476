import React, { useState, useEffect, useCallback } from "react";
import { Button, Table, Tabs, Space, Tag } from "antd";
import { PlusOutlined, LinkOutlined } from "@ant-design/icons";
import { useAuth0 } from "../../react-auth0-spa";
import { Link } from "react-router-dom";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import axios from "axios";
import AbbinaFornitoreModal from "./AbbinaFornitoreModal";
import AbbinaClienteModal from "./AbbinaClienteModal";
import DissociateFornitoreButton from "./DissociateFornitoreButton";
import DissociateClienteButton from "./DissociateClienteButton";
import { GET_FORNITORE } from "../../graphql/queries/fornitore";
import { GET_CLIENTI } from "../../graphql/queries/cliente";

const { TabPane } = Tabs;

const TabCentroPrenotazioni = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const [loading, setLoading] = useState(true);
  const env = isProd ? "prod" : "dev";
  const [fornitori, setFornitori] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [abbinaFornitoreModalVisible, setAbbinaFornitoreModalVisible] =
    useState(false);
  const [abbinaClienteModalVisible, setAbbinaClienteModalVisible] =
    useState(undefined);

  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const fetchData = useCallback(
    async (_isProd, orgId) => {
      try {
        const token = await getTokenSilently();
        const variablesFornitori = {
          where: {
            centro_servizi_pointer: {
              exists: true,
            },
            org_pointer: {
              have: {
                objectId: {
                  equalTo: orgId,
                },
              },
            },
          },
        };

        const variablesClienti = {
          where: {
            OR: [
              {
                terzista_org_pointer: {
                  exists: true,
                },
              },
              {
                invite_status: {
                  exists: true,
                },
              },
            ],
            org_pointer: {
              have: {
                objectId: {
                  equalTo: orgId,
                },
              },
            },
          },
        };

        const fornitori = axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: GET_FORNITORE,
            variables: variablesFornitori,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const clienti = axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: GET_CLIENTI,
            variables: variablesClienti,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return await Promise.all([fornitori, clienti]);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, graphqlUrl]
  );

  const triggerRefresh = useCallback(async () => {
    fetchData(isProd, props.orgId).then((responses) => {
      setFornitori(responses[0].data);
      setClienti(responses[1].data);
      setLoading(false);
    });
  }, [fetchData, isProd, props.orgId]);

  const onCreateFornitore = useCallback(async () => {
    setAbbinaFornitoreModalVisible(false);
    triggerRefresh();
  }, [triggerRefresh]);

  const onAbbinaCliente = useCallback(async () => {
    setAbbinaClienteModalVisible(false);
    triggerRefresh();
  }, [triggerRefresh]);

  useEffect(() => {
    setLoading(true);
    fetchData(isProd, props.orgId).then((responses) => {
      setFornitori(responses[0].data);
      setClienti(responses[1].data);
      setLoading(false);
    });
  }, [fetchData, isProd, props.orgId]);

  if (loading) {
    return <div>Caricamento...</div>;
  }

  const columnsFornitori = [
    {
      title: "ID Org",
      dataIndex: "centro_servizi_pointer",
      render: (value) => {
        return <Link to={`/clients/${value.objectId}`}>{value.objectId}</Link>;
      },
    },
    {
      title: "ID Fornitore",
      dataIndex: "objectId",
    },
    {
      title: "Ragione Sociale",
      dataIndex: "ragione_sociale",
    },
    {
      title: "Citta",
      dataIndex: "citta",
    },
    {
      title: "P.IVA",
      dataIndex: "partita_iva",
    },
    {
      title: "Telefono",
      dataIndex: "telefono",
    },
    {
      title: "Dissocia",
      dataIndex: "objectId",
      render: (_,record) => {
        return <DissociateFornitoreButton orgId={props.orgId} triggerRefresh={triggerRefresh} value={record}>  </DissociateFornitoreButton>;
      }
    },
  ];

  const columnsClienti = [
    {
      title: "ID Org",
      dataIndex: "terzista_org_pointer",
      render: (value, record) => {
        if (!value || !value.objectId) {
          return (
            <>
              <Button
                type="primary"
                size="small"
                onClick={() => setAbbinaClienteModalVisible(record.objectId)}
              >
                Abbina Org <LinkOutlined />
              </Button>
              <AbbinaClienteModal
                orgId={props.orgId}
                clienteId={record.objectId}
                visible={abbinaClienteModalVisible === record.objectId}
                onCreate={onAbbinaCliente}
                onCancel={() => setAbbinaClienteModalVisible(undefined)}
              />
            </>
          );
        }
        return <Link to={`/clients/${value.objectId}`}>{value.objectId}</Link>;
      },
    },
    {
      title: "Ragione Sociale",
      dataIndex: "cognome",
    },
    {
      title: "P.IVA",
      dataIndex: "partita_iva",
    },
    {
      title: "Invito",
      dataIndex: "invite_status",
      render: (value) => {
        const colors = {
          pending: "default",
          accepted: "green",
        };

        if (value) {
          return <Tag color={colors[value]}>{value.toUpperCase()}</Tag>;
        }
        return "";
      },
    },
    {
      title: "Dissocia",
      render: (_, record) => {
        if (record.terzista_org_pointer && record.terzista_org_pointer.objectId) {
          return <DissociateClienteButton orgId={props.orgId} triggerRefresh={triggerRefresh} value={record}>  </DissociateClienteButton>;
        }
      }
    },
  ];

  return (
    <Tabs defaultActiveKey="FornitoriAndClienti">
      <TabPane tab={`Fornitori (${fornitori.length})`} key="Fornitori">
        <div>
          <Button
            style={{ marginTop: "15px" }}
            type="primary"
            size="large"
            onClick={() => setAbbinaFornitoreModalVisible(true)}
          >
            Aggiungi Fornitore <PlusOutlined />
          </Button>
          <AbbinaFornitoreModal
            orgId={props.orgId}
            // onResultAcked={onResultAcked}
            // removebgModalResult={removebgModalResult}
            visible={abbinaFornitoreModalVisible}
            onCreate={onCreateFornitore}
            onCancel={() => setAbbinaFornitoreModalVisible(false)}
          />
        </div>
        <Space direction="vertical">
          <Table
            dataSource={fornitori}
            columns={columnsFornitori}
            pagination={{ pageSize: 100 }}
          />
        </Space>
      </TabPane>
      <TabPane tab={`Clienti (${clienti.length})`} key="Clienti">
        <Space direction="vertical">
          <Table
            dataSource={clienti}
            columns={columnsClienti}
            pagination={{ pageSize: 100 }}
          />
        </Space>
      </TabPane>
    </Tabs>
  );
};

export default TabCentroPrenotazioni;
