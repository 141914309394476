import { UPDATE_CATEGORIA_SERVIZI } from "../queries/categorie_servizi";
import axios from "axios";

export const updateCategorieServizi = async (graphql, fields, id) => {
    const response = await axios({
        method: "post",
        url: graphql.url,
        data: {
          query: UPDATE_CATEGORIA_SERVIZI,
          variables: {
            id,
            fields
          },
        },
        headers: {
          Authorization: `Bearer ${graphql.token}`,
        },
    });
    return response;
};

