import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Breadcrumb,
  Card,
  Divider,
  Typography,
  Space,
  Badge,
  Table,
  Input,
  Button,
  message,
} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { ofcUrl } from "../../api_config.json";
import formatDistance from "date-fns/formatDistance";
import _ from "lodash";
import ParseLogo from "../OrgDetails/images/parse.png";
import LinearProgress from "@material-ui/core/LinearProgress";
import { it } from "date-fns/locale";
import DocPreview from "./DocPreview";

const query = `
    {
      org_docs(first: 10000){
        edges{
          node {
            objectId
            nome_documento
            pdf_form {
              url
            }
            regione
            updatedAt            
            org_pointer{
              objectId
              nome              
            }
          }
        }
      }
    }`;

const { Title } = Typography;

const Documenti = () => {
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { getTokenSilently } = useAuth0();
  const [items, setItems] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchData = useCallback(
    async (_isProd) => {
      try {
        const token = await getTokenSilently();

        let graphqlUrl = `${config.baseUrl[env]}/graphql`;

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query,
            variables: {},
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, env]
  );

  useEffect(() => {
    setLoading(true);
    fetchData(isProd).then((response) => {
      const newData = response.data.map((client) => {
        if (client.regione) {
          client.regione = client.regione.toUpperCase();
        }
        return client;
      });

      setItems(newData);
      setLoading(false);
    });
  }, [fetchData, isProd, setItems]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      _.get(record, dataIndex)
        ? _.get(record, dataIndex)
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      _.isEqual(searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const columns = [
    {
      title: "Nome Documento",
      dataIndex: "nome_documento",
      key: "nome_documento",
      width: "30%",
      ...getColumnSearchProps("nome_documento"),
    },
    {
      title: "Regione",
      dataIndex: "regione",
      key: "regione",
      align: "center",
      filters: items
        ?.map((item) => (item.regione ? item.regione.toUpperCase() : ""))
        .sort()
        .filter((value) => value)
        .filter(onlyUnique)
        .map((value) => {
          return {
            text: value,
            value,
          };
        }),
      onFilter: (value, record) =>
        record.regione && record.regione.indexOf(value) === 0,
      render: (regione, record) => {
        if (regione) {
          return regione;
        } else {
          return "";
        }
      },
    },
    {
      title: "ORG",
      dataIndex: ["org_pointer", "nome"],
      width: "30%",
      ...getColumnSearchProps(["org_pointer", "nome"]),
      render: (_, record) => {
        if (!record.org_pointer) {
          return <>❌</>;
        }
        return (
          <p>
            <Link to={`/clients/${record.org_pointer.objectId}`}>
              {record.org_pointer.nome}
            </Link>
          </p>
        );
      },
    },
    {
      title: "ORG ID",
      dataIndex: ["org_pointer", "objectId"],
      width: "10%",
      ...getColumnSearchProps(["org_pointer", "objectId"]),
    },
    {
      title: "Last update",
      dataIndex: "updatedAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
      width: "10%",
      render: (item) =>
        formatDistance(new Date(item), new Date(), { locale: it }),
    },
    {
      title: "Links",
      dataIndex: "objectId",
      key: "objectId",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <a
            target="blank"
            rel="noopener noreferrer"
            href={`${
              config.parseDashboard[isProd ? "prod" : "dev"]
            }/browser/org_doc?filters=[{"field":"objectId","constraint":"eq","compareTo":"${
              record.objectId
            }"}]`}
          >
            <img src={ParseLogo} width={20} alt="logo" />
          </a>
        );
      },
    },
  ];

  return (
    <div>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>Eterno</Breadcrumb.Item>
        <Breadcrumb.Item>Documenti</Breadcrumb.Item>
      </Breadcrumb>
      <Divider />
      <Title level={3} style={{ marginBottom: "40px" }}>
        <Space>
          Documenti Personalizzati
          {items && <Badge overflowCount={5000} count={items.length}></Badge>}
        </Space>
      </Title>
      {items && (
        <Table
          columns={columns}
          loading={loading && items.lenght === 0}
          rowKey="objectId"
          expandable={{
            expandedRowRender: (record) => {
              if (record.pdf_form && record.pdf_form.url) {
                return (
                  <object
                    data={record.pdf_form.url}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    style={{
                      width: "80vw",
                      height: "100vh",
                    }}
                  >
                    PDF embed not working
                  </object>
                );
              }
              return (
                <Card
                  bordered={false}
                  style={{ width: "80%" }}
                  extra={
                    <div>
                      <Button
                        type="link"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${
                          ofcUrl[isProd ? "prod" : "dev"]
                        }/index.php?page=modificaTemplateDocumento&documentId=${
                          record.objectId
                        }`}
                      >
                        Modifica su Eterno
                      </Button>
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        onClick={() => {
                          navigator.clipboard.writeText(record.documento);
                          message.success("Copied to clipboard");
                        }}
                      >
                        Copia Documento
                        <CopyOutlined />
                      </Button>
                    </div>
                  }
                >
                  <DocPreview id={record.objectId} />
                </Card>
              );
            },
            rowExpandable: (record) =>
              record.nome_documento !== "Not Expandable",
          }}
          dataSource={items}
        />
      )}
    </div>
  );
};

export default Documenti;
