import React from "react";
import { Alert } from "antd";
import ReactJson from "react-json-view";

const OutputPreview = ({ resultPdf, resultJson, code, error }) => {
  return (
    <>
      {resultPdf && (
        <object
          data={resultPdf}
          type="application/pdf"
          style={{
            minHeight: "500px",
            minWidth: "100%",
          }}
        >
          PDF embed not working
        </object>
      )}
      {resultJson && (
        <ReactJson
          src={resultJson}
          style={{
            "text-align": "left",
          }}
        ></ReactJson>
      )}
      {error && (
        <Alert
          message={
            code === "ID"
              ? "Impossibile generare il documento PDF"
              : "Impossibile estrapolare i dati dal documento"
          }
          type="error"
          showIcon={true}
        ></Alert>
      )}
    </>
  );
};

export default OutputPreview;
