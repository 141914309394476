import { GET_CLIENTE_BY_ID, GET_CLIENTI } from "../queries/cliente";
import axios from "axios";

export const fetchClienti = async (graphql, variables) => {
  const response = await axios({
    method: "post",
    url: graphql.url,
    data: {
      query: GET_CLIENTI,
      variables,
    },
    headers: {
      Authorization: `Bearer ${graphql.token}`,
    },
  });
  return response;
};

export const fetchClienteById = async (graphql, id) => {
  const response = await axios({
    method: "post",
    url: graphql.url,
    data: {
      query: GET_CLIENTE_BY_ID,
      variables: {
        id,
      },
    },
    headers: {
      Authorization: `Bearer ${graphql.token}`,
    },
  });
  return response;
};
