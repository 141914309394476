import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import {
  AppsHub,
  Attachments,
  CreateBackup,
  CreatePaymentPage,
  DocScanner,
  Documents,
  DownloadCollection,
  GiottoAnnunciFunebri,
  GiottoBackgrounds,
  GiottoClientProjects,
  GiottoImages,
  GiottoSymbols,
  IframePage,
  OrgDetails,
  Orgs,
  PrivateRoute,
  RestoreBackups,
  Sidebar,
  StatusGrid,
  SystemEvents,
} from "./components";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { orgFilters } from "./config";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./App.css";
import { Spin, Layout } from "antd";
import EasterEgg from "react-easter";
import {
  QueryParamProvider,
  transformSearchStringJsonSafe,
} from "use-query-params";
import { SendNotifications } from "./components/SendNotifications";

const konamiCode = ["w", "i", "n", "w", "i", "n"];

const youtubeVideos = [
  "https://www.youtube.com/embed/uhscMsBhNhw?autoplay=1",
  "https://www.youtube.com/embed/DLzxrzFCyOs?autoplay=1",
];

const { Content } = Layout;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3D5864",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

function ImageEnhancementProjects() {
  return <Attachments filter="image-enhancement" />;
}

function RemoveBgProjects() {
  return <Attachments filter="removebg" />;
}

function MagicBrushProjects() {
  return <Attachments filter="magic-brush" />;
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { loading } = useAuth0();

  const [token, setToken] = useState();

  const queryStringifyOptions = {
    transformSearchString: transformSearchStringJsonSafe,
  };

  const filterNames = orgFilters.reduce((acc, orgFilter) => {
    acc += orgFilter.filterName + "|";
    return acc;
  }, "");

  const { getTokenSilently } = useAuth0();

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          zIndex: 999,
          textAlign: "center",
          marginTop: "20%",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  getTokenSilently().then((token) => setToken(token));

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <ScrollToTop />
          <QueryParamProvider
            ReactRouterRoute={Route}
            stringifyOptions={queryStringifyOptions}
          >
            <Layout style={{ minHeight: "100vh" }}>
              <Sidebar />
              <Layout className="site-layout">
                <Content style={{ padding: "20px", backgroundColor: "#fff" }}>
                  <Switch>
                    <PrivateRoute path="/" exact component={AppsHub} />
                    <PrivateRoute path="/ci" component={StatusGrid} />
                    <PrivateRoute
                      path="/deals-map"
                      component={() => (
                        <IframePage
                          title="Mappa Trattative"
                          src="https://analytics.zoho.eu/open-view/10370000003158176"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/mappa"
                      component={() => (
                        <IframePage
                          title="Mappa Agenzie Funebri Italiane"
                          src="https://www.google.com/maps/d/embed?mid=1rDmaRP71B2RX9WT2d4V3L2VNonxLn0Ac&hl=it"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/analytics/giotto/annunci-funebri"
                      component={() => (
                        <IframePage
                          title="Giotto + Annunci Funebri Usage"
                          src="https://datastudio.google.com/embed/reporting/2da07086-b67e-4a70-be84-eca93e43423d/page/p_18s5u5y3mc"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/analytics/giotto/strumenti-foto"
                      component={() => (
                        <IframePage
                          title="Giotto + Strumenti Foto"
                          src="https://datastudio.google.com/embed/reporting/2da07086-b67e-4a70-be84-eca93e43423d/page/p_k42j0g4ouc"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/analytics/giotto/magicframe"
                      component={() => (
                        <IframePage
                          title="MagicFrame Usage"
                          src="https://datastudio.google.com/embed/reporting/2da07086-b67e-4a70-be84-eca93e43423d/page/p_q27gx743mc"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/analytics/giotto"
                      component={() => (
                        <IframePage
                          title="Giotto - Usage Overview"
                          src="https://datastudio.google.com/embed/reporting/2da07086-b67e-4a70-be84-eca93e43423d/page/4VDGB"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/analytics/usage"
                      component={() => (
                        <IframePage
                          title="Platform Usage Overview"
                          src="https://analytics.zoho.eu/open-view/10370000003745715/60420cf44e0e76883263f0a0de5107ce"
                        />
                      )}
                    />
                    <PrivateRoute
                      path={["/clients/:activeFilter(" + filterNames + ")"]}
                      component={Orgs}
                    />
                    <PrivateRoute
                      path="/clients/:orgId/:tabName?"
                      component={OrgDetails}
                    />
                    <PrivateRoute path="/clients" component={Orgs} />
                    <PrivateRoute
                      path="/system-events"
                      component={SystemEvents}
                    />
                    <PrivateRoute
                      path="/giotto/editor_images_default"
                      component={GiottoImages}
                    />
                    <PrivateRoute
                      path="/giotto/editor_backgrounds_default"
                      component={GiottoBackgrounds}
                    />
                    <PrivateRoute
                      path="/giotto/editor_symbols_default"
                      component={GiottoSymbols}
                    />
                    <PrivateRoute
                      path="/giotto/client-projects"
                      component={GiottoClientProjects}
                    />
                    <PrivateRoute
                      path="/giotto/removebg"
                      component={RemoveBgProjects}
                    />
                    <PrivateRoute
                      path="/giotto/image-enhancement"
                      component={ImageEnhancementProjects}
                    />
                    <PrivateRoute
                      path="/giotto/magic-brush"
                      component={MagicBrushProjects}
                    />
                    <PrivateRoute
                      path="/giotto/annunci-funebri"
                      component={GiottoAnnunciFunebri}
                    />
                    <PrivateRoute
                      path="/db/backup/restore"
                      render={routeProps => <RestoreBackups {...routeProps} accessToken={token} />}                      
                    />
                    <PrivateRoute
                      path="/db/backup/new"
                      render={routeProps => <CreateBackup {...routeProps} accessToken={token} />}
                    />
                    <PrivateRoute
                      path="/db/backup/download"
                      render={routeProps => <DownloadCollection {...routeProps} accessToken={token} />}
                    />
                    <PrivateRoute
                      path="/sales/create-payment-page"
                      component={CreatePaymentPage}
                    />
                    <PrivateRoute
                      path="/sales/create-quote"
                      component={() => (
                        <IframePage
                          title="Platform Usage Overview"
                          src="https://forms.zohopublic.eu/onoranzefunebricloud/form/PreventivatoreOFC/formperma/Zb9ySNHNLweYBadMVxjYYZJG1GR5VoeBnRi2lCZDuKY"
                        />
                      )}
                    />
                    <PrivateRoute
                      path="/sales/create-contact"
                      component={() => (
                        <IframePage
                          title="Crea contatto"
                          src="https://share-eu1.hsforms.com/1mv_6vWr6QDeUSG9CKq7rpAf1gx3"
                        />
                      )}
                    />
                    <PrivateRoute path="/documents" component={Documents} />
                    <PrivateRoute path="/doc-scanner" component={DocScanner} />
                    <PrivateRoute
                      path="/send-notifications"
                      component={SendNotifications}
                    />
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </QueryParamProvider>
        </BrowserRouter>
        <EasterEgg keys={konamiCode} timeout={10000}>
          <iframe
            title="oooops"
            src={
              youtubeVideos[Math.floor(Math.random() * youtubeVideos.length)]
            }
            frameborder="0"
            allowfullscreen
          />
        </EasterEgg>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
