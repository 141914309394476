import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const query = `query getDocHTML($id: ID!) {
    org_doc(id: $id) {
      documento
    }
  }`;

const DocPreview = (props) => {
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";
  const { getTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState();

  const fetchData = useCallback(
    async (_isProd) => {
      try {
        const token = await getTokenSilently();

        let graphqlUrl = `${config.baseUrl[env]}/graphql`;

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query,
            variables: {
              id: props.id,
            },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, env, props.id]
  );

  useEffect(() => {
    setLoading(true);
    fetchData(isProd).then((response) => {
      setItem(response.data);
      setLoading(false);
    });
  }, [fetchData, isProd, setItem]);

  if (loading) {
    return <>Caricamento...</>;
  }

  if (!loading && item && item.documento) {
    return <div dangerouslySetInnerHTML={{ __html: item.documento }} />;
  }

  return <></>;
};

export default DocPreview;
