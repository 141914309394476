import React, { useState, useEffect } from "react";
import _ from "lodash";
import {
  Breadcrumb,
  Button,
  Divider,
  message,
  Skeleton,
  Space,
  Tabs,
  Typography,
} from "antd";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { AimOutlined, MessageOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import TabDettagliOrg from "./TabDettagliOrg";
import TabRemovebgOrg from "./TabRemovebgOrg";
import TabImageEnhancementOrg from "./TabImageEnhancementOrg";
import TabOrgUsers from "./TabOrgUsers";
import TabGiotto from "./TabGiotto";
import TabAnnunciFunebri from "./TabAnnunciFunebri";
import TabOrgDocs from "./TabOrgDocs";
import TabOrgTimeline from "./TabOrgTimeline";
import TabFatturazioneElettronica from "./TabFatturazioneElettronica";
import TabCentroPrenotazioni from "./TabCentroPrenotazioni";
import TabServizi from "./TabServizi";
import TabFatture from "./TabFatture";
import OrgLinks from "./OrgLinks";
import { decodeStr } from "../../utils";
import "./OrgDetails.css";
import TabOrgFeatures from "./TabOrgFeatures/TabOrgFeatures";

const { Title } = Typography;
const { TabPane } = Tabs;

const aladinGifs = [
  "https://media.giphy.com/media/TrYXjFjgam6A0/giphy.gif",
  "https://media.giphy.com/media/3o7abldj0b3rxrZUxW/giphy.gif",
  "https://media.giphy.com/media/b5LTssxCLpvVe/giphy.gif",
  "https://media.giphy.com/media/xWGnQfQN5bauY/giphy.gif",
  "https://media.giphy.com/media/P8MxmGnjmytws/giphy.gif",
  "https://media.giphy.com/media/26tPplGWjN0xLybiU/giphy.gif",
  "https://media.giphy.com/media/3NtY188QaxDdC/giphy.gif",
  "https://media.giphy.com/media/10UeedrT5MIfPG/giphy.gif",
  "https://media.giphy.com/media/ma6P4l3O53uX6/giphy.gif",
  "https://media.giphy.com/media/wi7Bpses8HgaI/giphy.gif",
  "https://media.giphy.com/media/H5C8CevNMbpBqNqFjl/giphy.gif",
  "https://media.giphy.com/media/H5C8CevNMbpBqNqFjl/giphy.gif",
];

const OrgDetails = (props) => {
  const { orgId, tabName } = props.match.params;
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState({});
  const { isProd } = useAppContext();

  const { getTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = await getTokenSilently();

        const response = await fetch(
          `${config.baseUrl[isProd ? "prod" : "dev"]}/orgs/${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        return responseData;
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().then(({ data }) => {
      setItem(data);
      setLoading(false);
    });
  }, [orgId, getTokenSilently, isProd]);

  const assumeOrgRole = async (orgId) => {
    try {
      message.loading("Ogni suo desiderio è un ordine", 2);
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/assume-org-role`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            orgId,
          }),
        }
      );

      await response.text();
      const gif = aladinGifs[Math.floor(Math.random() * aladinGifs.length)];
      message.success(<img alt="gif" src={gif} />, 2);
    } catch (error) {
      message.error("Si é verificato un problema: " + error.message);
      console.error(error);
    }
  };

  if (loading) {
    return <Skeleton loading={true} />;
  }

  return (
    <div style={{ margin: "16px 16px" }}>
      <div>{loading && <LinearProgress />}</div>
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>
          <Link to="/clients">Clienti</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {decodeStr(_.get(item, "org.nome", ""))}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Divider />

      <Title
        level={1}
        style={{
          marginBottom: "40px",
          color: `${item.org.stato === "attiva" ? "green" : "red"}`,
        }}
      >
        <Space>
          {decodeStr(_.get(item, "org.nome", ""))}
          <Button
            onClick={() => {
              assumeOrgRole(item.org.objectId).then(() => {
                window.open(
                  "https://eterno.onoranzefunebricloud.com/logout.php",
                  "_blank"
                );
              });
              return;
            }}
            style={{ marginLeft: "20px", marginBottom: "10px" }}
            size="large"
            icon={<AimOutlined />}
          >
            Entra con Super User (Eterno)
          </Button>
          <Button
            onClick={() => {
              assumeOrgRole(item.org.objectId).then(() => {
                window.open(
                  "https://editor.giotto.onoranzefunebricloud.com/logout",
                  "_blank"
                );
              });
              return;
            }}
            style={{ marginBottom: "10px" }}
            size="large"
            icon={<AimOutlined />}
          >
            Entra con Super User (Giotto)
          </Button>
          <Link
            to={{
              pathname: "/send-notifications",
              record: {
                orgId,
              },
            }}
          >
            <Button
              style={{ marginBottom: "10px" }}
              size="large"
              icon={<MessageOutlined />}
            >
              Invia notifica
            </Button>
          </Link>
          <OrgLinks
            org={item.org}
            size="large"
            style={{ marginLeft: "5px", marginBottom: "5px" }}
          />
        </Space>
        {item.org.logo && (
          <img
            alt="Logo"
            src={item.org.logo.url}
            style={{ maxWidth: "100px", float: "right" }}
          />
        )}
      </Title>
      <Tabs
        defaultActiveKey={tabName}
        size="large"
        onTabClick={(key) => history.push(`/clients/${orgId}/${key}`)}
      >
        <TabPane tab="Info" key="dettagli">
          <TabDettagliOrg org={item.org} />
        </TabPane>
        <TabPane tab="Usage" key="usage">
          <iframe
            title="Usage"
            src={`https://analytics.zoho.eu/open-view/10370000003745715/60420cf44e0e76883263f0a0de5107ce?ZOHO_CRITERIA=%22Organizzazione%22.%22objectId%22%3D'${orgId}'`}
            style={{
              height: "100vh",
              width: "calc(100vw - 270px)",
            }}
            height="100%"
            width="100%"
          />
        </TabPane>
        <TabPane
          tab={`Servizi (${item.org.percent_services_used || 0}%)`}
          key="servizi"
          disabled={typeof item.org.services_by_billing_periods === "undefined"}
        >
          <TabServizi org={item.org} />
        </TabPane>
        <TabPane
          tab="Fatture"
          key="fatture"
          disabled={typeof item.org.invoices_by_cal_years === "undefined"}
        >
          <TabFatture org={item.org} />
        </TabPane>
        <TabPane tab={`Users (${item.users.length})`} key="users">
          <TabOrgUsers users={item.users} />
        </TabPane>
        <TabPane tab="Funzionalità" key="features">
          <TabOrgFeatures
            orgId={orgId}
            org={item.org}
            features={item.features}
            disabledFeatures={item.disabledFeatures}
          />
        </TabPane>
        <TabPane
          tab={`Removebg (${
            (item.removebgCredits?.freeCreditsAmount || 0) +
            (item.removebgCredits?.paidCreditsAmount || 0)
          })`}
          key="removebg"
        >
          <TabRemovebgOrg
            orgId={orgId}
            removebgCredits={item.removebgCredits}
            removebgOrders={item.removebgOrders}
          />
        </TabPane>
        <TabPane
          tab={`Migliora Foto`}
          key="imageEnhancement"
        >
          <TabImageEnhancementOrg
            orgId={orgId}
          />
        </TabPane>
        <TabPane tab="Giotto" key="giotto">
          <TabGiotto
            editorCanvases={item.editorCanvases}
            editorTemplates={item.editorTemplates}
            giottoImages={item.giottoImages}
            giottoBackgrounds={item.giottoBackgrounds}
          />
        </TabPane>
        <TabPane tab="AF" key="annunci-funebri">
          <TabAnnunciFunebri orgId={orgId} />
        </TabPane>
        <TabPane tab={`Documenti (${item.docs.length})`} key="documenti">
          <TabOrgDocs docs={item.docs} orgId={orgId} />
        </TabPane>
        <TabPane tab="Timeline" key="timeline-operazioni">
          <TabOrgTimeline orgId={orgId} />
        </TabPane>
        <TabPane
          disabled={item.fatturazione_attiva}
          tab="Aruba"
          key="fatturazione-elettronica"
        >
          <TabFatturazioneElettronica orgId={orgId} />
        </TabPane>
        <TabPane tab="Centro Prenotazioni" key="centro-prenotazioni">
          <TabCentroPrenotazioni orgId={orgId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default OrgDetails;
