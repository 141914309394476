import { DELETE_FORNITORE } from "../queries/fornitore";

import axios from "axios";

export const deleteFornitori = async (graphql, id) => {
  const response = await axios({
    method: "post",
    url: graphql.url,
    data: {
      query: DELETE_FORNITORE,
      variables: { id }
    },
    headers: {
      Authorization: `Bearer ${graphql.token}`,
    },
});
  return response;
};

