import React from "react";
import { message, Switch } from "antd";
import "moment/locale/it";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import axios from "axios";

const mutationCreateStatus = `
mutation CreateStatus($fields: CreateStatiFieldsInput) {
  createStati(input: {fields: $fields}) {
    stati {
      objectId
    }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const fetchStatus = `
query FetchStatus($where: StatiWhereInput) {
  statis(where: $where) {
    edges {
        node {
              objectId
        }
      }
  }
}`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const FieldAttivaCentroServizi = (props) => {
  const { org } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";

  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const updateOrgDetails = async (orgId, fields) => {
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/orgs/${orgId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            fields,
          }),
        }
      );

      await response.text();
    } catch (error) {
      message.error("Si é verificato un problema: " + error.message);
      console.error(error);
    }
  };

  const createReservationStatuses = async (orgId) => {
    try {
      const token = await getTokenSilently();

      const variables = {};

      const statuses = [
        {
          fields: {
            org_pointer: {
              link: orgId,
            },
            order: 1,
            label: "Da Gestire",
            nome: "da_gestire",
            type: "reservation",
          },
          paramsForFetch: [
            {
              servizi_offerti_pointer: { exists: false },
            },
          ],
        },
        {
          fields: {
            org_pointer: {
              link: orgId,
            },
            order: 2,
            label: "Accettata",
            nome: "presa_in_carico",
            type: "reservation",
          },
          paramsForFetch: [
            {
              servizi_offerti_pointer: { exists: false },
            },
          ],
        },
        {
          fields: {
            org_pointer: {
              link: orgId,
            },
            order: 3,
            label: "In Lavorazione",
            nome: "in_lavorazione",
            type: "reservation",
          },
          paramsForFetch: [
            {
              servizi_offerti_pointer: { exists: false },
            },
          ],
        },
        {
          fields: {
            org_pointer: {
              link: orgId,
            },
            order: 4,
            label: "Completata",
            nome: "completata",
            type: "reservation",
          },
          paramsForFetch: [
            {
              servizi_offerti_pointer: { exists: false },
            },
          ],
        },
      ];

      for (const status of statuses) {
        const where = { AND: [...status.paramsForFetch] };
        for (const [key, value] of Object.entries(status.fields)) {
          if (key.endsWith("_pointer")) {
            where.AND.push({
              [key]: { have: { objectId: { equalTo: value.link } } },
            });
          } else {
            where.AND.push({
              [key]: { equalTo: value },
            });
          }
        }
        const result = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: fetchStatus,
            variables: { where },
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (result && result.data.length === 0) {
          variables.fields = status.fields;
          await axios({
            method: "post",
            url: graphqlUrl,
            data: {
              query: mutationCreateStatus,
              variables,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          //Se anche solo una volta è stata trovata una corrispondenza, meglio non toccare nulla per evitare di cambiare configurazioni esistenti
          return;
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleChange = async (value) => {
    const fields = [
      {
        key: "fornitore_centro_prenotazioni",
        value: value,
      },
    ];

    await updateOrgDetails(org.objectId, fields);
    if (value) {
      await createReservationStatuses(org.objectId);
    }

    message.success("Fatto");
  };

  return (
    <Switch
      onChange={handleChange}
      defaultChecked={org.fornitore_centro_prenotazioni}
    />
  );
};

export default FieldAttivaCentroServizi;
