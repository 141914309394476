import React, { useCallback, useState, useEffect } from "react";
import { Card } from "antd";
import config from "../../api_config.json";
import ReactCompareImage from "react-compare-image";
import { useAuth0 } from "../../react-auth0-spa";
import { useAppContext } from "../../context";
import { fetchAllegati } from "../../graphql/controllers/fetchAllegati";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress } from "@material-ui/core";

const TabImageEnhancementOrg = (props) => {
  const [skipIndex, setSkipIndex] = useState(1);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const fetchData = useCallback(
    async (skip = 0) => {
      const limit = 12;
      const env = isProd ? "prod" : "dev";

      const graphqlUrl = `${config.baseUrl[env]}/graphql`;

      try {
        const token = await getTokenSilently();
        const graphql = {
          url: graphqlUrl,
          token,
        };
        const variablesFetchAllegati = {
          where: {
            org_pointer: {
              have: {
                objectId: {
                  equalTo: props.orgId,
                },
              },
            },
            isImageEnhancement: {
              equalTo: true,
            },
          },
          skip: skip * limit,
          first: 12,
        };

        const response = await fetchAllegati(graphql, variablesFetchAllegati);

        return response;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently, props]
  );

  useEffect(() => {
    fetchData(0).then(({ data }) => {
      if (data.length === 0) {
        setHasMore(false);
      }
      setItems(data);
    });
  }, [fetchData, setItems]);

  return (
    <div>
      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setSkipIndex(skipIndex + 1);
          fetchData(skipIndex).then(({ data }) => {
            setItems(items.concat(data));
            if (data.length === 0) {
              setHasMore(false);
            }
          });
        }}
        hasMore={hasMore}
        loader={<LinearProgress />}
      >
        <div className="grid-container">
          {items.map((allegato) => {
            if (!allegato.enhancementRawImage) {
              return "";
            }
            return (
              <div className="grid-item">
                <Card>
                  <div style={{ maxWidth: "25vw" }}>
                    <ReactCompareImage
                      leftImage={allegato.allegato.url}
                      rightImage={allegato.enhancementRawImage}
                    />
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default TabImageEnhancementOrg;
