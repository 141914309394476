import React from "react";
import {
  Popconfirm,
  ConfigProvider,
} from "antd";
import moment from "moment";
import "moment/locale/it";
import locale from "antd/lib/locale/it_IT";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import updateOrgDetails from './updateOrgDetails';
import MomentDatePicker from "../MomentDatePicker";

const FieldDataScadenza = (props) => {
  const { org, fieldName } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  let defaultValue;

  try {
    defaultValue = moment(org[fieldName].iso)
  } catch (err) {
    defaultValue = '';
  }

  const [value, setValue] = React.useState(defaultValue);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  if (!org[fieldName]) {
    return ""
  }

  const showPopconfirm = (value) => {
    setValue(value)
    setVisible(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);

    const fields = [
      {
        key: fieldName,
        value: new Date(value),
        isDate: true,
      },
    ];

    updateOrgDetails(getTokenSilently, config, isProd, org.objectId, fields).then(() => {
      setVisible(false);
      setConfirmLoading(false);
    });
  };

  const handleCancel = () => {
    setVisible(false);
  };


  return (
    <ConfigProvider locale={locale}>
      <Popconfirm
        title="Sei sicuro di voler aggiornare la org?"
        open={visible}
        onConfirm={handleOk}
        okButtonProps={{ loading: confirmLoading }}
        onCancel={handleCancel}
      >
        <MomentDatePicker
          onChange={showPopconfirm}
          defaultValue={value}
        />
      </Popconfirm>
    </ConfigProvider>
  );
};

export default FieldDataScadenza;
