export const GET_ALLEGATI = `
query fetchAllegatiFuneralService($where: AllegatiWhereInput, $first: Int, $skip: Int) {
  allegatis(where: $where, first: $first, skip: $skip, order: createdAt_DESC) {
    edges {
      node {
        objectId
        nome
        allegato {
          url
          name
        }
        isImageEnhancement
        isBlurBg
        isRemovebg
        isMagicEraser
        service_reservation_pointer {
          objectId
        }
        removebgRawImage
        enhancementRawImage
        defunto_pointer {
          objectId
          nome
          cognome
        }
        createdAt
        updatedAt
        removebg_provider
        ACL{
          roles{
            write
            read
            roleName
          }
        }
      }
    }
  }
}`;
