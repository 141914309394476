import React, { useCallback } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import { fetchServizi } from "../../graphql/controllers/fetchServizi";
import { fetchCategorieServizi } from "../../graphql/controllers/fetchCategorieServizi";
import { fetchServiziOfferti } from "../../graphql/controllers/fetchServiziOfferti";
import { updateServizi } from "../../graphql/controllers/updateServizi";
import { updateServiziOfferti } from "../../graphql/controllers/updateServiziOfferti";
import { updateCategorieServizi } from "../../graphql/controllers/updateCategorieServizi";
import { updateCliente } from "../../graphql/controllers/updateCliente";
import { deleteFornitori } from "../../graphql/controllers/deleteFornitore";
import { fetchFornitori } from "../../graphql/controllers/fetchFornitori";


const updateServiziACL = async (graphql, centroServiziId, terzistaId) => {
  const response = await fetchServizi(graphql, centroServiziId);
  const promises = [];
  for (const servizio of response.data) {
    promises.push(
      await updateServizi(graphql, {
        ACL: {
          roles: [
            ...servizio.ACL?.roles.filter((role) => role.roleName !== terzistaId),
          ],
        },
      }, servizio.objectId)
    );
    if (
      (response.data.length < 10 && promises.length === response.data.length) ||
      promises.length % 10 === 0
    ) {
      await Promise.all(promises);
    }
  }
};

const updateCategorieServiziACL = async (graphql, centroServiziId, terzistaId) => {
  const response = await fetchCategorieServizi(graphql, centroServiziId)
  const promises = [];
  for (const categoria of response.data) {
    promises.push(
      await updateCategorieServizi(graphql, {
        ACL: {
          roles: [
            ...categoria.ACL?.roles.filter((role) => role.roleName !== terzistaId),
          ],
        },
      }, categoria.objectId)
    );
    if (
      (response.data.length < 10 && promises.length === response.data.length) ||
      promises.length % 10 === 0
    ) {
      await Promise.all(promises);
    }
  }
};

const updateServiziOffertiACL = async (graphql, centroServiziId, terzistaId) => {
  const response = await fetchServiziOfferti(graphql, centroServiziId);
  const promises = [];
  for (const servizioOfferto of response.data) {
    if (!servizioOfferto.ACL?.roles) {
      return;
    }
    promises.push(
      await updateServiziOfferti(graphql, {
        ACL: {
          roles: [
            ...servizioOfferto.ACL?.roles?.filter((role) => role.roleName !== terzistaId),
          ],
        },
      }, servizioOfferto.objectId)
    );
    if (
      (response.data.length < 10 && promises.length === response.data.length) ||
      promises.length % 10 === 0
    ) {
      await Promise.all(promises);
    }
  }
};

export const DissociateClienteButton = (props) => {
  const { value, triggerRefresh, orgId } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const env = isProd ? "prod" : "dev";

  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const dissociateCliente = useCallback(
    async (cliente) => {
      console.log("Dissociate Cliente", cliente);
      try {
        const token = await getTokenSilently();
        const graphql = {
          url: graphqlUrl,
          token,
        }

        const variablesClienti = {
          id: cliente.objectId,
          fields: {
            invite_status: "pending",
            terzista_org_pointer: null,
          },
        };

        const variablesGetFornitore = {
          where: {
            centro_servizi_pointer: {
              have: {
                objectId: {
                  equalTo: orgId,
                }
              }
            },
            org_pointer: {
              have: {
                objectId: {
                  equalTo: cliente.terzista_org_pointer.objectId,
                }
              }
            },
          },
        };

        const fornitori = await fetchFornitori(graphql, variablesGetFornitore);

        const fornitore = fornitori.data.length > 0 ? fornitori.data[0] : {};

        await deleteFornitori(graphql, fornitore.objectId)

        const response = await updateCliente(graphql, variablesClienti)

        await updateServiziOffertiACL(
          { url: graphqlUrl, token },
          orgId,
          cliente.terzista_org_pointer.objectId,
        );

        await updateCategorieServiziACL(
          { url: graphqlUrl, token },
          orgId,
          cliente.terzista_org_pointer.objectId,
        );

        await updateServiziACL(
          { url: graphqlUrl, token },
          orgId,
          cliente.terzista_org_pointer.objectId,
        );

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, graphqlUrl, orgId]
  );

  return (
    <Popconfirm
      title="Dissocia il cliente"
      description="Sei sicuro di voler dissociare questo cliente?"
      onConfirm={async () => {
        await dissociateCliente(value);
        await triggerRefresh();
      }}
      okText="Si"
      cancelText="No"
    >
      <Button danger
        icon={<DeleteOutlined />}
        style={{ width: 60 }}
      ></Button>
    </Popconfirm>
  );
};

export default DissociateClienteButton;