import React, { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "../../react-auth0-spa";
import { Form, Modal, Spin } from "antd";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import axios from "axios";
import AbbinaFornitoreForm from "./AbbinaFornitoreForm";

const mutationCreateFornitore = `
mutation CreateFornitore($fields: CreateFornitoriFieldsInput) {
    createFornitori(input: {fields: $fields}) {
      fornitori {
        objectId
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const queryAvailableFornitori = `
  query GetAvailableFornitori {
    organizzaziones(where: {fornitore_centro_prenotazioni: {equalTo: true}}) {
      edges {
        node {
              objectId
              nome
              partita_iva
              citta
              indirizzo
              iban
              telefono1
              email
        }
      }
    }
  }`
  .replace(/\r?\n|\r/g, "")
  .replace(/\t/g, " ");

const title = "Abbina Fornitore";

const AbbinaFornitoreModal = (props) => {
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();
  const [loading, setLoading] = useState(true);
  const env = isProd ? "prod" : "dev";
  const [form] = Form.useForm();
  const [availableFornitori, setAvailableFornitori] = useState([]);

  const graphqlUrl = `${config.baseUrl[env]}/graphql`;

  const createFornitore = useCallback(
    async (values) => {
      try {
        const token = await getTokenSilently();

        const fornitore = availableFornitori.find(
          (org) => org.objectId === values.orgId
        );

        const variables = {
          fields: {
            ragione_sociale: fornitore.nome,
            partita_iva: fornitore.partita_iva,
            citta: fornitore.citta,
            indirizzo: fornitore.indirizzo,
            iban: fornitore.iban,
            telefono: fornitore.telefono1,
            email: fornitore.email,
            centro_servizi_pointer: {
              link: values.orgId,
            },
            org_pointer: {
              link: props.orgId,
            },
          },
        };

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: mutationCreateFornitore,
            variables,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, availableFornitori, props.orgId, graphqlUrl]
  );

  const fetchAvailableFornitori = useCallback(
    async (_isProd) => {
      try {
        const token = await getTokenSilently();

        const response = await axios({
          method: "post",
          url: graphqlUrl,
          data: {
            query: queryAvailableFornitori,
            variables: {},
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return response.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [getTokenSilently, graphqlUrl]
  );

  useEffect(() => {
    setLoading(true);
    fetchAvailableFornitori(isProd)
      .then((response) => {
        setAvailableFornitori(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [fetchAvailableFornitori, isProd, props.orgId]);

  return (
    <Modal
      visible={props.visible}
      title={title}
      okText="Salva"
      cancelText="Annulla"
      maskClosable={false}
      onCancel={() => {
        setLoading(false);
        props.onCancel();
      }}
      onOk={() => {
        setLoading(true);
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            createFornitore(values)
              .then(() => {
                setLoading(false);
                props.onCreate();
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((info) => {
            console.error("Validate Failed:", info);
          });
      }}
    >
      {!loading && (
        <AbbinaFornitoreForm
          form={form}
          availableFornitori={availableFornitori}
        />
      )}
      {loading && <Spin size="large" />}
    </Modal>
  );
};

export default AbbinaFornitoreModal;
