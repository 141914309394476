import {
    GET_SERVIZI_OFFERTI
} from "../queries/servizi_offerti";
import axios from "axios";

export const fetchServiziOfferti = async (graphql, centroServiziId) => {
    const response = await axios({
        method: "post",
        url: graphql.url,
        data: {
            query: GET_SERVIZI_OFFERTI,
            variables: { orgId: centroServiziId },
        },
        headers: {
            Authorization: `Bearer ${graphql.token}`,
        },
    });
    return response;
};

