import React from "react";
import { message, Switch } from "antd";
import "moment/locale/it";
import { useAuth0 } from "../../react-auth0-spa";
import config from "../../api_config.json";
import { useAppContext } from "../../context";

const FieldAttivaAF = (props) => {
  const { org } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const updateOrgDetails = async (orgId, fields) => {
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${config.baseUrl[isProd ? "prod" : "dev"]}/orgs/${orgId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            fields,
          }),
        }
      );

      await response.text();
    } catch (error) {
      message.error("Si é verificato un problema: " + error.message);
      console.error(error);
    }
  };

  const setOrgFeaturePreference = async (orgId, data) => {
    try {
      const token = await getTokenSilently();

      const response = await fetch(
        `${
          config.baseUrl[isProd ? "prod" : "dev"]
        }/setFeaturePreference/${orgId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const handleChange = async (value) => {
    const fields = [
      {
        key: "integrazione_annunci_funebri",
        value: value,
      },
    ];

    await updateOrgDetails(org.objectId, fields);
    await setOrgFeaturePreference(org.objectId, {
      product: "giotto",
      key: `project.cordogli`,
      disabled: !value,
    });
    message.success("Fatto");
  };

  return (
    <Switch
      onChange={handleChange}
      defaultChecked={org.integrazione_annunci_funebri}
    />
  );
};

export default FieldAttivaAF;
