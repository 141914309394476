export const GET_CATEGORIA_SERVIZI= `
query FindCategorieServiziByOrgId($orgId: ID) {
  categorie_servizis(where: {
    org_pointer: {
      have: {
        objectId: {
          equalTo: $orgId
        }
      }
    }
  }, first: 10000) {
    edges {
      node {
        objectId
        ACL{
          roles{
            write
            read
            roleName
          }
        }
      }
    }
  }
}`;

export const UPDATE_CATEGORIA_SERVIZI = `
mutation DisabbinaClienteCategorieServizi($id: ID!, $fields: UpdateCategorie_serviziFieldsInput) {
  updateCategorie_servizi(input: {id: $id, fields: $fields}) {
    categorie_servizi {
      objectId
    }
  }
}`;
