import { Checkbox, Tooltip } from "antd";
import React, { useEffect, useState } from "react";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const FeatureItem = (props) => {
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    setIsChecked(!props.disabled);
  }, [props.disabled]);

  return (
    <div style={{ margin: 5 }}>
      <Checkbox
        name={props.item.action}
        checked={isChecked}
        onChange={() =>
          props.handleClick({
            product: props.product,
            key: `${props.group}.${props.item.action}`,
            disabled: isChecked,
          })
        }
      >
        {props.item.label ? (
          props.item.description ? (
            <Tooltip title={props.item.description}>{props.item.label}</Tooltip>
          ) : (
            props.item.label
          )
        ) : (
          capitalizeFirstLetter(props.item.action)
        )}
      </Checkbox>
    </div>
  );
};

export default FeatureItem;
