import React, { useState, useCallback, useEffect } from "react";
import { Button, Tag, Divider, Table, Statistic, Card, Row, Col } from "antd";
import { useAuth0 } from "../../react-auth0-spa";
import format from "date-fns/format";
import { PlusOutlined } from "@ant-design/icons";
import RemovebgModal from "./RemovebgModal";
import config from "../../api_config.json";
import { useAppContext } from "../../context";
import ReactCompareImage from "react-compare-image";
import formatDistance from "date-fns/formatDistance";
import { it } from "date-fns/locale";
import InfiniteScroll from "react-infinite-scroll-component";
import { LinearProgress } from "@material-ui/core";
import { fetchAllegati } from "../../graphql/controllers/fetchAllegati";

const TabRemovebgOrg = (props) => {
  const [removebgModalVisible, setRemovebgModalVisible] = useState(false);
  const [removebgModalResult, setRemovebgModalResult] = useState();
  const [skipIndex, setSkipIndex] = useState(1);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const {
    removebgCredits = { freeCreditsAmount: 0, paidCreditsAmount: 0 },
    removebgOrders = [],
  } = props;
  const { getTokenSilently } = useAuth0();
  const { isProd } = useAppContext();

  const fetchData = useCallback(
    async (skip = 0) => {
      const limit = 12;
      const env = isProd ? "prod" : "dev";

      const graphqlUrl = `${config.baseUrl[env]}/graphql`;

      try {
        const token = await getTokenSilently();
        const graphql = {
          url: graphqlUrl,
          token,
        };
        const variablesFetchAllegati = {
          where: {
            org_pointer: {
              have: {
                objectId: {
                  equalTo: props.orgId,
                },
              },
            },
            isRemovebg: {
              equalTo: true,
            },
          },
          skip: skip * limit,
          first: 12,
        };

        const response = await fetchAllegati(graphql, variablesFetchAllegati);

        return response;
      } catch (error) {
        console.error(error);
      }
    },
    [isProd, getTokenSilently, props]
  );

  useEffect(() => {
    fetchData(0).then(({ data }) => {
      if (data.length === 0) {
        setHasMore(false);
      }
      setItems(data);
    });
  }, [fetchData, setItems]);

  const createRemovebgOrder = async (values) => {
    try {
      const token = await getTokenSilently();

      const payload = {
        ...values,
        orgId: props.orgId,
      };
      const response = await fetch(
        `${config.removebg[isProd ? "prod" : "dev"]}/synthetic-transaction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(payload),
        }
      );

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      console.error(error);
    }
  };

  const onResultAcked = () => {
    setRemovebgModalVisible(false);
    setRemovebgModalResult("");
  };

  const onCreate = (values) => {
    createRemovebgOrder(values)
      .then((response) => {
        setRemovebgModalResult("OK");
      })
      .catch((error) => {
        console.error(error);
        setRemovebgModalResult("ERROR");
      });
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => format(new Date(data), "dd/MM/yyyy"),
    },
    {
      title: "Ora",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data) => format(new Date(data), "HH:mm"),
    },
    {
      title: "Crediti Acquistati",
      dataIndex: "paidCreditsAmount",
      key: "paidCreditsAmount",
    },
    {
      title: "Crediti Gratuiti",
      dataIndex: "freeCreditsAmount",
      key: "freeCreditsAmount",
    },
    { title: "Note", dataIndex: "note", key: "note" },
    {
      title: "Costo",
      dataIndex: "isPaid",
      key: "isPaid",
      render: (isPaid) => {
        return (
          <Tag color={isPaid ? "green" : "blue"}>
            {isPaid ? "PAID" : "FREE"}
          </Tag>
        );
      },
    },
    {
      title: "ID Pagamento",
      dataIndex: "paymentReference",
      key: "paymentReference",
    },
    {
      title: "Stato",
      dataIndex: "transactionStatus",
      key: "transactionStatus",
      render: (transactionStatus) => {
        const colors = {
          approved: "green",
          cancelled: "volcano",
          pending: "free",
        };

        return (
          <Tag color={colors[transactionStatus]}>
            {transactionStatus.toUpperCase()}
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <Row gutter={9}>
        <Col span={3}>
          <Card>
            <Statistic
              title="Gratuiti"
              value={removebgCredits.freeCreditsAmount}
              precision={0}
              valueStyle={{
                color:
                  removebgCredits.freeCreditsAmount > 0 ? "#3f8600" : "#cf1322",
              }}
              suffix="crediti"
            />
          </Card>
        </Col>
        <Col span={3}>
          <Card>
            <Statistic
              title="Acquistati"
              value={removebgCredits.paidCreditsAmount}
              precision={0}
              valueStyle={{
                color:
                  removebgCredits.paidCreditsAmount > 0 ? "#3f8600" : "#cf1322",
              }}
              suffix="crediti"
            />
          </Card>
        </Col>
        <Col>
          <Card style={{ height: "100%" }}>
            <Button
              style={{ marginTop: "15px" }}
              type="primary"
              size="large"
              onClick={() => setRemovebgModalVisible(true)}
            >
              Crea Ordine <PlusOutlined />
            </Button>
            <RemovebgModal
              onResultAcked={onResultAcked}
              removebgModalResult={removebgModalResult}
              visible={removebgModalVisible}
              onCreate={onCreate}
              onCancel={() => setRemovebgModalVisible(false)}
            />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Table dataSource={removebgOrders} columns={columns} />
      <Divider />

      <InfiniteScroll
        dataLength={items.length}
        next={() => {
          setSkipIndex(skipIndex + 1);
          fetchData(skipIndex).then(({ data }) => {
            setItems(items.concat(data));
            if (data.length === 0) {
              setHasMore(false);
            }
          });
        }}
        hasMore={hasMore}
        loader={<LinearProgress />}
      >
        <div className="grid-container">
          {items.map((allegato) => {
            if (!allegato.removebgRawImage) {
              return "";
            }
            return (
              <div className="grid-item">
                <Card>
                  <div style={{ maxWidth: "25vw" }}>
                    <ReactCompareImage
                      leftImage={allegato.allegato.url}
                      rightImage={allegato.removebgRawImage}
                    />
                  </div>
                  <Tag style={{ marginTop: "10px" }}>
                    {formatDistance(new Date(allegato.updatedAt), new Date(), {
                      locale: it,
                    })}
                  </Tag>
                  <Tag
                    style={{ marginTop: "10px" }}
                    color={
                      allegato.removebg_provider === "pixianai"
                        ? "cyan"
                        : "purple"
                    }
                  >
                    {allegato.removebg_provider || "removebg"}
                  </Tag>
                </Card>{" "}
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default TabRemovebgOrg;
