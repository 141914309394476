import React, { useEffect, useState } from "react";
import { Card, Col, Switch, Tooltip } from "antd";
import FeatureItem from "./FeatureItem";

function isFeatureDisabled(disabledFeatures, action) {
  if (!action) {
    action = "show";
  }
  if (disabledFeatures) {
    const disabledFeature = disabledFeatures.find(
      (item) => item.action === action && item.disabled
    );
    if (disabledFeature) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function returnIsDefaultText(items, licence) {
  const feature = items.find((item) => item.action === "show");
  if (
    feature &&
    feature.plans &&
    feature.plans.some((item) => item === licence)
  ) {
    return "Incluso ";
  }
}

const FeatureGroup = (props) => {
  const [showItem, setShowItem] = useState(undefined);
  const [showEnabled, setShowEnabled] = useState(false);

  useEffect(() => {
    setShowItem(props.items.find((item) => item.action === "show"));
  }, [props.items]);

  useEffect(() => {
    if (isFeatureDisabled(props.disabledItems, "show")) {
      setShowEnabled(false);
    } else {
      setShowEnabled(true);
    }
  }, [props.disabledItems]);

  return (
    <Col span={6} style={{ padding: 10 }}>
      <Card
        title={
          showItem?.label ? (
            showItem.description ? (
              <Tooltip title={showItem.description}>
                {showItem.label}
              </Tooltip>
            ) : (
              showItem.label
            )
          ) : (
            capitalizeFirstLetter(props.group)
          )
        }
        extra={
          showItem && (
            <div>
              {returnIsDefaultText(props.items, props.orgLicence)}
              <Switch
                checked={showEnabled}
                onChange={() => {
                  props.handleClick({
                    product: props.product,
                    key: `${props.group}.${showItem.action}`,
                    disabled: showEnabled,
                  });
                }}
              />
            </div>
          )
        }
        style={{ height: "100%" }}
      >
        {showEnabled &&
          props.items
            .filter((item) => item.action !== "show")
            .sort((a, b) =>
              a.label
                ? a.label.localeCompare(b.label ? b.label : b.action)
                : a.action.localeCompare(b.label ? b.label : b.action)
            )
            .map((item) => {
              return (
                <FeatureItem
                  key={item.action}
                  item={item}
                  product={props.product}
                  group={props.group}
                  disabled={isFeatureDisabled(props.disabledItems, item.action)}
                  handleClick={props.handleClick}
                />
              );
            })}
      </Card>
    </Col>
  );
};

export default FeatureGroup;
